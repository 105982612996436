import * as zod from 'zod'

import { ProjectRole } from 'types/permissions/permissions'

export const roleOption = [
  { value: ProjectRole.OWNER, label: 'Owner' },
  { value: ProjectRole.CONTRIBUTOR, label: 'Contributor' },
  { value: ProjectRole.VIEWER, label: 'Viewer' },
]

export const inviteMembersSchema = zod
  .object({
    members: zod
      .array(zod.object({ id: zod.string() }))
      .nonempty({ message: 'Please select at least one member or group' }),
    externalMembers: zod.array(
      zod.object({
        id: zod.string(),
        email: zod.string(),
        firstName: zod
          .string()
          .min(2, 'This field is required and should have at least 2 symbols')
          .regex(/^([a-zA-Z]|\s)*$/, 'Special characters are not allowed'),
        lastName: zod
          .string()
          .min(2, 'This field is required and should have at least 2 symbols')
          .regex(/^([a-zA-Z]|\s)*$/, 'Special characters are not allowed'),
        agency: zod.string().min(1, 'This field is required'),
      }),
    ),
  })
  .passthrough()

export const emailSchema = zod.string().email()

const namesPattern = /^(.+?)[-._](.+)@.+$/
export const emailToNames = (email: string): { firstName: string; lastName: string } => {
  const result = email.match(namesPattern)

  if (!result) {
    return { firstName: '', lastName: '' }
  }

  const [, firstName, lastName] = result
  return { firstName, lastName }
}
