import React, { PropsWithChildren } from 'react'

// Modal registry to store registered modals
let PROJECT_MODAL_REGISTRY: {
  [id: string]: {
    comp: React.FC<any>
  }
} = {}

// Listeners for updates to the modal registry
const listeners: (() => void)[] = []

// Notify all listeners about updates
const notifyListeners = () => {
  listeners.forEach(listener => listener())
}

// Register a modal
export const registerProjectModal = <T extends React.FC<any>>(id: string, comp: T): void => {
  if (!PROJECT_MODAL_REGISTRY[id]) {
    PROJECT_MODAL_REGISTRY[id] = { comp }
    PROJECT_MODAL_REGISTRY = { ...PROJECT_MODAL_REGISTRY } // Create a new object to trigger a re-render
    notifyListeners() // Notify listeners about the registry update
  }
}

// Subscribe to updates
const subscribe = (callback: () => void) => {
  listeners.push(callback)
  return () => {
    const index = listeners.indexOf(callback)
    if (index >= 0) listeners.splice(index, 1)
  }
}

// Hook to access the modal registry using useSyncExternalStore
const useProjectModalRegistry = () =>
  React.useSyncExternalStore(
    subscribe, // Subscribe function
    () => PROJECT_MODAL_REGISTRY, // Snapshot function
  )

// Provider component
export const ProjectModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const modalRegistry = useProjectModalRegistry()

  return (
    <>
      {children}
      {Object.entries(modalRegistry).map(([id, t]) => {
        const ModalComponent = t.comp
        return <ModalComponent key={id} id={id} />
      })}
    </>
  )
}
