// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RYMLD{padding:4px 8px;color:var(--wpp-grey-color-700)}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/canvas/components/selectOsGroup/SelectOsGroup.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,+BAAA","sourcesContent":[".emptyText {\n  padding: 4px 8px;\n  color: var(--wpp-grey-color-700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyText": `RYMLD`
};
export default ___CSS_LOADER_EXPORT___;
