// copy of core's src/utils/workspace.ts
import {
  MayBeNull,
  NavigationTree,
  SelectedHierarchy,
  SelectedHierarchyItem,
  HierarchyNodeType,
  NavigationTreeNode,
  DefaultHierarchyLevelType,
  HierarchyCustomNodeType,
  HierarchyNode,
} from '@wpp-open/core'

export type WorkspaceHierarchyIds = string[]

/**
 * Doesn't include tenant level in the result
 */
export const resolveHierarchyPath = ({
  validHierarchyId,
  navigationTree,
}: {
  validHierarchyId: MayBeNull<string>
  navigationTree: NavigationTree
}): WorkspaceHierarchyIds => {
  if (!validHierarchyId || !navigationTree) {
    return []
  }

  const { rootId, mapping } = navigationTree

  const findParentNodeAzId = (childAzId: string) => {
    const parentNode = Object.values(mapping).find(node => node.children.includes(childAzId))
    const parentAzId = parentNode?.azId || null

    return parentAzId !== rootId ? parentAzId : null
  }

  const hierarchyPath: WorkspaceHierarchyIds = []
  let currentAzId: MayBeNull<string> = validHierarchyId

  do {
    hierarchyPath.unshift(currentAzId)
    currentAzId = findParentNodeAzId(currentAzId)

    if (currentAzId && hierarchyPath.includes(currentAzId)) {
      throw new Error('Circular dependency in Navigation Tree')
    }
  } while (currentAzId)

  return hierarchyPath
}

// "hidden level" is tenant level
export const resolveSelectedHierarchy = ({
  activeWorkspaceId,
  activeHierarchyWithHiddenLevel,
  navigationTreeWithHiddenLevel,
}: {
  activeWorkspaceId: MayBeNull<string>
  activeHierarchyWithHiddenLevel: string[]
  navigationTreeWithHiddenLevel: NavigationTree
}): SelectedHierarchy => {
  const { rootId } = navigationTreeWithHiddenLevel

  const selectedRootAzId = activeWorkspaceId || rootId
  // Include tenant level in provided context
  const selectedHierarchyIds = [rootId, ...activeHierarchyWithHiddenLevel]

  return {
    azId: selectedRootAzId,
    mapping: Object.fromEntries(
      selectedHierarchyIds.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : selectedHierarchyIds[index - 1],
          navigationTreeWithHiddenLevel,
        }),
      ]),
    ),
  }
}

const getHierarchyStructureItem = ({
  azId,
  parentAzId,
  navigationTreeWithHiddenLevel,
}: {
  azId: string
  parentAzId?: string
  navigationTreeWithHiddenLevel: NavigationTree
}): SelectedHierarchyItem => {
  const node: MayBeNull<NavigationTreeNode> = navigationTreeWithHiddenLevel.mapping[azId]

  return (
    node && {
      azId,
      name: node.name,
      type: node.type as HierarchyNodeType,
      customTypeName: node.customTypeName,
      parentAzId,
    }
  )
}

export const isHierarchyNode = (node: NavigationTreeNode): node is HierarchyNode =>
  node.type === HierarchyCustomNodeType ||
  Object.values(DefaultHierarchyLevelType).includes(node.type as DefaultHierarchyLevelType)

export const isWorkspaceIdValid = ({
  workspaceAzId,
  navigationTree,
}: {
  workspaceAzId: MayBeNull<string>
  navigationTree: NavigationTree
}): boolean => {
  // Empty result is valid. It means a root workspace level
  if (!workspaceAzId) {
    return true
  }

  const { rootId, mapping } = navigationTree
  const isRoot = workspaceAzId === rootId

  // Return empty invalid result if entity for workspaceAzId is not found
  if (!mapping[workspaceAzId]) {
    return false
  }

  // Validate node type
  return isHierarchyNode(mapping[workspaceAzId]) && !isRoot
}
