import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState } from 'components/common/emptyState/EmptyState'
import { Flex } from 'components/common/flex/Flex'
import { NativeAppCard } from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeAppCard'
import styles from 'pages/project/components/canvas/components/appPikerModal/nativeApps/NativeApps.module.scss'
import { SelectedApp } from 'pages/project/components/canvas/components/appPikerModal/types'
import { NativeAppsCategory, NativeAppsCommercialModel, NativeAppVersionDTO } from 'types/products/nativeApp'

interface Props {
  search: string
  filters: {
    categories: NativeAppsCategory[]
    commercialModel: NativeAppsCommercialModel
  }
  selectedApps: SelectedApp[]
  onSelectApp: (args: SelectedApp[]) => void
}

export const SelectedApps = ({ search, filters, selectedApps, onSelectApp }: Props) => {
  const { t } = useTranslation()

  const handleSelectVersions = useCallback(
    (app: SelectedApp, versions: NativeAppVersionDTO[]) => {
      const newApps = selectedApps.filter(selectedApp => selectedApp.id !== app.id)
      if (versions.length) {
        newApps.push({
          id: app.id,
          type: app.type,
          category: app.category,
          commercialModel: app.commercialModel,
          name: app.name,
          shortDescription: app.shortDescription,
          logoUrl: app.logoUrl,
          versions,
        })
      }

      onSelectApp(newApps)
    },
    [onSelectApp, selectedApps],
  )

  const filtersCount = useMemo(() => {
    return Object.values(filters).filter(filter => !!filter.length).length
  }, [filters])
  const resultsFiltered = !!search || filtersCount

  const filteredApps = useMemo(() => {
    let result = selectedApps
    // search by text
    if (search) {
      result = result.filter(app => {
        const searchToken = search.toLowerCase()
        return app.name.toLowerCase().includes(searchToken) || app.shortDescription?.toLowerCase().includes(searchToken)
      })
    }

    // search by filters
    if (filtersCount) {
      result = result
        .filter(app =>
          filters.commercialModel && app.commercialModel ? filters.commercialModel === app.commercialModel : true,
        )
        .filter(app => (app.category && filters.categories.length ? filters.categories.includes(app.category) : true))
    }

    return result
  }, [filters.categories, filters.commercialModel, filtersCount, search, selectedApps])

  return !filteredApps.length ? (
    <EmptyState
      title={resultsFiltered ? t('common.no_search_results') : t('product.no_products.title')}
      filtersApplied={!!search}
      description={resultsFiltered ? t('common.no_results_description') : t('product.no_products.subtitle')}
      testToken="results"
    />
  ) : (
    <Flex className={styles.grid}>
      {filteredApps.map(app => (
        <NativeAppCard
          app={app}
          key={app.id}
          onSelectVersions={versions => handleSelectVersions(app, versions)}
          selectedVersions={app?.versions || []}
          isMiroEnabled={true}
        />
      ))}
    </Flex>
  )
}
