import { WppListItem, WppSelect, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchTenantGroupsApi } from 'api/tenant/queries/useFetchTenantGroupsApi'
import { useToast } from 'hooks/useToast'
import styles from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroup.module.scss'
import { TenantGroup } from 'types/tenants/tenant'

interface Props {
  'data-testid'?: string
  onChange: (value: string) => void
  value?: string
  initialValue?: MayBeNull<TenantGroup>
  className?: string
  onBlur?: () => void
  name?: string
}

export const SelectOsGroup = ({
  'data-testid': dataTestId,
  onChange,
  value,
  className,
  onBlur,
  initialValue,
  name = 'groupId',
}: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const {
    osContext: { tenant },
  } = useOs()
  // use custom label to display label w/o loaded option

  const [selectedLabel, setSelectedLabel] = useState<string>('')

  useEffect(() => {
    if (!initialValue) return

    setSelectedLabel(initialValue.name)
  }, [initialValue])

  const [search, setSearch] = useState('')
  const {
    data: groups,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
  } = useFetchTenantGroupsApi({
    params: { tenantId: tenant.id, search: search.trim().length >= 2 ? search.trim() : undefined },
  })

  useEffect(() => {
    if (!isError) return

    showToast({
      type: 'error',
      message: t('project.phase.settings_modal.group_load_failed'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const filteredGroups = useMemo(() => groups.filter(({ type }) => type !== 'SYSTEM'), [groups])

  return (
    <WppSelect
      value={value}
      displayValue={selectedLabel}
      required
      className={className}
      size="s"
      data-testid={dataTestId}
      name={name}
      infinite
      loading={isLoading}
      infiniteLastPage={!hasNextPage}
      loadMore={fetchNextPage as () => Promise<any>}
      placeholder={t('project.phase.settings_modal.group_placeholder')}
      withSearch
      onWppChange={e => {
        onChange(e.detail.value)
      }}
      onWppSearchValueChange={(e: CustomEvent) => setSearch(e.detail)}
      dropdownConfig={{
        onHidden: onBlur,
      }}
    >
      {!isLoading && search.trim().length >= 2 && !filteredGroups.length ? (
        <WppTypography type="s-body" className={styles.emptyText}>
          {t('common.search_no_results')}
        </WppTypography>
      ) : (
        filteredGroups.map(({ id, name }) => (
          <WppListItem
            key={id}
            value={id}
            onWppChangeListItem={e => {
              // `onWppChange` handler in `WppSelect` will not handle click on the selected item
              const isDeselecting = value === e.detail.value
              if (isDeselecting) {
                onChange('')
                setSelectedLabel('')
                return
              }

              setSelectedLabel(name)
            }}
          >
            <span slot="label">{name}</span>
          </WppListItem>
        ))
      )}
    </WppSelect>
  )
}
