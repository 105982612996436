import { WppCard, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/project/components/canvas/components/appPickerSkeleton/AppPickerSkeleton.module.scss'

export const AppPickerSkeleton = () => {
  const cards = useMemo(() => Array.from({ length: 10 }), [])
  return (
    <div className={styles.grid} data-testid="app-picker-skeleton">
      {cards.map((_, index) => (
        <WppCard className={styles.card} key={index} type="single" size="s">
          <Flex gap={12}>
            <WppSkeleton variant="rectangle" height="40px" width="40px" />

            <Flex gap={4} direction="column">
              <WppSkeleton variant="rectangle" height="24px" width="176px" />
              <WppSkeleton variant="rectangle" height="20px" width="108px" />
            </Flex>
          </Flex>

          <WppSkeleton variant="rectangle" className="wpp-spacing-8-top" height="22px" width="100%" />

          <Flex gap={8} className="wpp-spacing-16-top">
            <WppSkeleton variant="rectangle" height="22px" width="95px" />
            <WppSkeleton variant="rectangle" height="22px" width="130px" />
          </Flex>
        </WppCard>
      ))}
    </div>
  )
}
