import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useIsPermitted } from 'hooks/useIsPermitted'
import { MayBeNull } from 'types/common/utils'
import { AppPermissions } from 'types/permissions/permissions'
import { Template } from 'types/projects/template'

export const useTemplateRole = (template: MayBeNull<Template>) => {
  const {
    osContext: { userDetails },
  } = useOs()
  const { isPermitted } = useIsPermitted()

  const isOwner = useMemo(() => {
    return template?.createdBy?.id === userDetails.id
  }, [template?.createdBy?.id, userDetails.id])

  const isOwnerOrGlobalManage = isOwner || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const isGlobalManage = isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const canCreateBlueprint = isPermitted(AppPermissions.ORCHESTRATION_PUBLISH_BLUEPRINT) || isGlobalManage

  return { isOwner, isOwnerOrGlobalManage, isGlobalManage, canCreateBlueprint }
}
