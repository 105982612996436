import { AttachmentMetadata, MayBeNull } from '@wpp-open/core'

import { NativeAppType } from 'types/products/nativeApp'
import { ProjectFromTenantDTO } from 'types/projects/fromTenant'
import { ProcessType, ProjectType } from 'types/projects/projects'
import {
  ActivityFiles,
  ActivityItem,
  ApplicationItem,
  Container,
  IConnection,
  PhaseItemType,
} from 'types/projects/workflow'
import { TenantGroup } from 'types/tenants/tenant'

export interface User {
  id: string
  email: string
  firstname?: string
  lastname?: string
  avatarThumbnail?: AttachmentMetadata
  avatarUrl: MayBeNull<string>
}

export interface Template {
  id: string
  name: string
  tags?: string[]
  phasesStats: [{ appsCount: number }]
  projectId: string
  createdBy?: User
  createdAt: string
  types: ProjectType[]
  processType: ProcessType
  description?: string
  tenant?: MayBeNull<ProjectFromTenantDTO>
  hasMiroApps?: boolean
  groups?: MayBeNull<TenantGroup[]>
  workflowEditable: boolean
  templateState: TemplateState
  isBlueprint?: boolean
  shortDescription: MayBeNull<string>
  files?: ActivityFiles[]
}

export interface TemplatesCount {
  linear: number
  fluid: number
}

export interface TemplateApplication {
  id: string
  type: NativeAppType
  externalAppId: string
  externalAppName: string
  name?: string
  agencyExternalId?: string
  logoUrl?: string
  isOutdated?: boolean
}

export interface TemplateActivity {
  id: string
  name?: string
}

export interface TemplatePhaseItem {
  id: string
  orderNumber: number
  itemType: PhaseItemType
  item: TemplateActivity | TemplateApplication
}

export interface TemplatePhase {
  id: string
  name: string
  orderNumber: number
  phaseItems: TemplatePhaseItem[]
  // description?: string
}

export interface LinearTemplateDetail extends Template {
  phases: TemplatePhase[]
}

export interface TemplateContainer extends Omit<Container, 'itemId'> {
  item: ApplicationItem | ActivityItem
}

export interface FluidTemplateDetail extends Template {
  containers: TemplateContainer[]
  connections: IConnection[]
}

export enum TemplateState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  PUBLISHED_TO_MARKETPLACE = 'PUBLISHED_TO_MARKETPLACE',
  UNPUBLISHED_CHANGES = 'UNPUBLISHED_CHANGES',
}
