import { WppTag } from '@platform-ui-kit/components-library-react'

import { t } from 'i18next'
import { TemplateState } from 'types/projects/template'

export const TemplateStatusTag = ({ templateState }: { templateState: TemplateState }) => {
  const variantMap = {
    [TemplateState.DRAFT]: 'warning',
    [TemplateState.PUBLISHED]: 'positive',
    [TemplateState.PUBLISHED_TO_MARKETPLACE]: 'positive',
    [TemplateState.UNPUBLISHED_CHANGES]: 'warning',
  } as const

  const labelMap = {
    [TemplateState.DRAFT]: t('template.status_draft'),
    [TemplateState.PUBLISHED]: t('template.status_published'),
    [TemplateState.PUBLISHED_TO_MARKETPLACE]: t('template.status_published_to_marketplace'),
    [TemplateState.UNPUBLISHED_CHANGES]: t('template.status_unpublished_changes'),
  }

  return <WppTag variant={variantMap[templateState]} data-testid="template-status" label={labelMap[templateState]} />
}
