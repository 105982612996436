import { AttachmentMetadata } from '@wpp-open/core'

import { MayBeNull } from 'types/common/utils'
import { ProjectRole } from 'types/permissions/permissions'
import { TenantGroup } from 'types/tenants/tenant'

export interface User {
  id: string
  email: string
  firstname?: string
  lastname?: string
  avatarThumbnail: AttachmentMetadata
  avatarUrl: MayBeNull<string>
}

export enum WrikeIntegrationStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  NO_ACCOUNT = 'NO_ACCOUNT',
  INVITATION_PENDING = 'INVITATION_PENDING',
}

export interface Members extends User {
  projectId: string
  role: ProjectRole
  groups?: TenantGroup[]
  agency?: string
  jobTitle?: string
  isExternal?: boolean
  wrikeConnectedAt?: string
  wrikeEmail?: string
  wrikeIntegrationStatus?: WrikeIntegrationStatus
  wrikeExternalFirstName?: string
  wrikeExternalLastName?: string
  wrikeInvitationSentAt: string
}
