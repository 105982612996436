import { WppButton, WppLabel, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePatchWorkflowTemplateBlueprintApi } from 'api/templates/mutations/usePatchWorkflowTemplateBlueprintApi'
import { Flex } from 'components/common/flex/Flex'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useUploadProjectFilesApi } from 'hooks/attachments/useUploadProjectFilesApi'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { TemplateTagControl } from 'pages/project/components/canvas/components/saveAsTemplateModal/TemplateTagControl'
import { mimeToExtensionMappingForPresentation } from 'pages/project/components/files/utils'
import {
  VALIDATION,
  templateValidationSchemaBlueprint,
  ProjectTypeOption,
} from 'pages/templates/components/blueprintModal/utils'
import styles from 'pages/templates/components/createNewTemplateModal/CreateNewTemplateModal.module.scss'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectType } from 'types/projects/projects'
import { Template } from 'types/projects/template'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'
import { bytes, formatBytes } from 'utils/files'

interface Props extends NiceModalWrappedProps {
  template: Template
}

interface BaseFormValues {
  name: string
  projectTypes: ProjectType[]
  tags?: string[]
  files?: File[]
  shortDescription?: string
  description?: string
}

const AddBlueprintModal = ({ isOpen, template, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { name, description, types, tags, files, shortDescription } = template

  const defaultValues: BaseFormValues = useMemo(() => {
    const mappedFiles =
      files?.map(file => ({
        ...file,
        url: file.name,
        type: file.fileType,
      })) || []

    return {
      name: name,
      projectTypes: types,
      description: description || '',
      shortDescription: shortDescription || '',
      tags,
      types,
      files: mappedFiles as unknown as File[],
    }
  }, [description, files, name, shortDescription, tags, types])

  const form = useForm({ defaultValues, validationSchema: templateValidationSchemaBlueprint })

  const { mutateAsync: addBlueprint } = usePatchWorkflowTemplateBlueprintApi()
  const handleUploadBlueprintFiles = useUploadProjectFilesApi()

  const [hasError, setError] = useState(false)

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const typeOptions: ProjectTypeOption[] = useMemo(() => {
    return (Object.keys(ProjectType) as ProjectType[]).map(type => ({
      value: type,
      // keep it in sync with `modals.save_project_as_template` translation node
      label: t(`modals.save_project_as_template.project_type_${type.toLowerCase()}`),
    }))
  }, [t])

  const onSubmit = handleSubmit(async values => {
    try {
      const { files: loadedFiles, name, shortDescription, description, projectTypes, tags } = values

      const files = !!loadedFiles?.length ? await handleUploadBlueprintFiles(loadedFiles, template.id) : []

      await addBlueprint({
        id: template.id,
        name: name.trim(),
        types: projectTypes,
        description: description?.trim() || undefined,
        shortDescription: shortDescription?.trim() || undefined,
        tags,
        files,
      })

      queryClient.invalidateQueries([ApiQueryKeys.WORKFLOW_TEMPLATES_INFINITE])
      onClose()
    } catch (e) {
      console.log(e)
      showToast({ type: 'error', message: t('project.files.toast_error_add_message')! })
    }
  })

  const fixLocalMsg = useCallback(
    (_: string, size: number) => `Images, presentations, and PDFs — up to ${formatBytes(bytes(size, 'mb'))} each.`,
    [],
  )

  const handleClose = () => {
    if (!isSubmitting) onClose()
  }

  return (
    <FormProvider {...form}>
      <SideModal
        size="m"
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleClose}
        onWppSideModalCloseComplete={onCloseComplete}
        data-testid="blueprint-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('template.add_blueprint_modal.title')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={24} className={styles.container}>
          <FormInput
            name="name"
            placeholder={t('template.add_blueprint_modal.field_name_placeholder')!}
            labelConfig={{ text: t('modals.create_new_template.field_name_label') }}
            required
            data-testid="blueprint-modal-name"
          />

          <FormInput
            name="shortDescription"
            placeholder={t('template.add_blueprint_modal.short_description_label_placeholder')!}
            labelConfig={{ text: t('template.add_blueprint_modal.short_description_label') }}
            required
            data-testid="blueprint-modal-short-description"
          />

          <FormTextareaInput
            name="description"
            warningThreshold={VALIDATION.description.max - 20}
            placeholder={t('modals.save_project_as_template.field_description_placeholder')!}
            labelConfig={{ text: t('modals.save_project_as_template.field_description_label') }}
            charactersLimit={VALIDATION.description.max}
            data-testid="blueprint-modal-description"
            required
          />

          <FormSelect
            name="projectTypes"
            type="multiple"
            options={typeOptions}
            placeholder={t('modals.create_new_template.field_project_placeholder')!}
            labelConfig={{ text: t('modals.create_new_template.field_project_label') }}
            data-testid="blueprint-modal-type"
            required
          />

          <TemplateTagControl />

          <Flex direction="column" gap={8}>
            <WppLabel
              data-testid="upload-files-label"
              config={{
                text: t('template.add_blueprint_modal.supportive_documents'),
                icon: 'wpp-icon-info',
                description: t('template.add_blueprint_modal.supportive_documents_description'),
              }}
              typography="s-strong"
              optional
            />

            <FormFileUpload
              name="files"
              maxLabelLength={45}
              locales={{ info: fixLocalMsg }}
              acceptConfig={mimeToExtensionMappingForPresentation}
              onWppChange={e => setError(e.detail.hasError)}
              multiple={true}
              maxFiles={10}
            />
          </Flex>
        </Flex>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" size="m" onClick={handleClose} disabled={isSubmitting}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton
            type="submit"
            size="m"
            loading={isSubmitting}
            disabled={hasError}
            variant="primary"
            data-testid="blueprint-save-button"
          >
            {t('template.add_blueprint_modal.publish_to_marketplace')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAddBlueprintModal } = createNiceModal<Props>(AddBlueprintModal, 'add-blueprint-modal')
