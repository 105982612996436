import {
  WppActionButton,
  WppButton,
  WppIconReset,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { TenantType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { FC, useMemo, useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useFetchProjectTypesApi } from 'api/projects/queries/useFetchProjectTypesApi'
import { Flex } from 'components/common/flex/Flex'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { useHierarchy } from 'hooks/useHierarchy'
import { MultiSelectAttribute } from 'pages/components/projectModal/components/attributes/MultiSelectAttribute'
import { SingleSelectAttribute } from 'pages/components/projectModal/components/attributes/SingleSelectAttribute'
import { HierarchyFilterControl } from 'pages/dashboard/components/hierarchyFilterControl/HierarchyFilterControl'
import { getAppliedFilters } from 'pages/dashboard/components/utils'
import { initialProjectFilters } from 'pages/dashboard/Dashboard'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'
import { ProjectFilter, ProjectOwnership, ProjectStatus } from 'types/projects/projects'
import { WITHOUT_HIERARCHY, capitalizeFirstLetter } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  filter: ProjectFilter
  attributes?: ProjectAttribute[]

  handleCloseModal(filter?: ProjectFilter): void
}

const ProjectFilterModal: FC<Props> = ({
  isOpen,
  onClose,
  onCloseComplete,
  handleCloseModal,
  filter,
  attributes = [],
}) => {
  const { t } = useTranslation()
  const { osContext } = useOs()
  const bodyRef = useRef<HTMLDivElement>(null)
  const isAgencyWorkspace = osContext.tenant.tenantType === TenantType.Agency

  const { data: projectTypesMap } = useFetchProjectTypesApi()

  const [, setParam] = useSearchParams()

  const { hierarchyOrder } = useHierarchy()

  const form = useForm({ defaultValues: filter })

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
    setValue,
    getValues,
  } = form

  const statusOptions = [
    { value: ProjectStatus.TO_DO, label: t('project.status.to_do') },
    { value: ProjectStatus.IN_PROGRESS, label: t('project.status.in_progress') },
    { value: ProjectStatus.COMPLETED, label: t('project.status.completed') },
    { value: ProjectStatus.ARCHIVED, label: t('project.status.archived') },
  ]

  console.log(projectTypesMap)

  const projectTypes = useMemo(() => {
    return projectTypesMap?.map(type => ({
      value: type.name,
      label: capitalizeFirstLetter(type.name.replace('_', ' ').toLowerCase()),
    }))
  }, [projectTypesMap])

  const onSubmit = handleSubmit(value => {
    const hierarchyFilters = Object.entries(value)
      .filter(([key, value]) => hierarchyOrder.includes(key) && !!value?.length)
      .map(([, value]) => value)
    const hasHierarchySet = !!hierarchyFilters.length

    setParam()
    if (!hasHierarchySet) {
      handleCloseModal({ ...value, hierarchy: undefined, includeEmptyHierarchyLevel: false })
      onClose()
      return
    }

    const hierarchyIds = hierarchyFilters.filter(id => id !== WITHOUT_HIERARCHY)
    // if we have at least one option with `WITHOUT_HIERARCHY` value, then length will be different
    const includeEmptyHierarchyLevel = hierarchyFilters.length !== hierarchyIds.length

    handleCloseModal({ ...value, hierarchy: hierarchyIds, includeEmptyHierarchyLevel })
    onClose()
  })

  const watchAllFields = watch()

  const isStatusDirty =
    watchAllFields.status?.length !== initialProjectFilters.status.length ||
    !watchAllFields.status?.every(value => initialProjectFilters.status.includes(value))

  const isOwnershipDirty = getValues('ownership') !== ProjectOwnership.ALL

  const onReset = () => {
    reset({
      ...initialProjectFilters,
      ...hierarchyOrder.reduce(
        (pre, curr) => ({
          ...pre,
          [curr]: '',
        }),
        {},
      ),
      attributes: attributes?.reduce((acc, curr) => ({ ...acc, [curr.contractName]: [] }), {}),

      // do not reset dashboards filters
      search: filter.search,
      tenant: filter.tenant,
    })
  }

  const appliedFilters = (filter: ProjectFilter) => {
    const { status, ownership, tenant, attributes, viewMode, ...rest } = filter

    return getAppliedFilters({ ...rest, ...attributes })
  }

  return (
    <>
      <FormProvider {...form}>
        <SideModal
          open={isOpen}
          // formConfig={{ onSubmit }}
          onWppSideModalClose={() => {
            handleCloseModal()
            onClose()
          }}
          onWppSideModalCloseComplete={onCloseComplete}
          size="s"
          data-testid="create-project-modal"
        >
          <WppTypography slot="header" type="2xl-heading" data-testid="filters-header">
            {t('modals.dashboard_filters.title')!}
          </WppTypography>
          <Flex slot="body" direction="column" gap={24} ref={bodyRef}>
            <WppSegmentedControl
              size="s"
              onWppChange={event => setValue('ownership', event.detail.value as ProjectOwnership)}
              value={getValues('ownership') ?? ProjectOwnership.ALL}
              data-testid="dashboard-project-ownership-filter"
            >
              <WppSegmentedControlItem value={ProjectOwnership.ALL}>
                {t('dashboard.field_my_project_label_all')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.MY}>
                {t('dashboard.field_my_project_label_my')!}
              </WppSegmentedControlItem>
              <WppSegmentedControlItem value={ProjectOwnership.SHARED_WITH_ME}>
                {t('dashboard.field_my_project_label_shared')!}
              </WppSegmentedControlItem>
            </WppSegmentedControl>
            <FormSelect
              name="status"
              type="multiple"
              data-testid="status-select"
              options={statusOptions}
              labelConfig={{ text: t('modals.dashboard_filters.field_status_label') }}
              placeholder={t('modals.dashboard_filters.field_status_placeholder')!}
              withFolder
              required
            />
            <FormSelect
              name="type"
              type="multiple"
              data-testid="type-select"
              options={projectTypes}
              labelConfig={{ text: t('modals.dashboard_filters.field_type_label') }}
              placeholder={t('modals.dashboard_filters.field_type_placeholder')!}
              withFolder
              required
            />
            {!isAgencyWorkspace && <HierarchyFilterControl />}
            {attributes.map(attribute => {
              switch (attribute.type) {
                case ProjectAttributeType.SINGLE_SELECT:
                  return (
                    <SingleSelectAttribute
                      formGroup="attributes"
                      key={attribute.id}
                      attribute={attribute}
                      placeholder={t('modals.dashboard_filters.attributes_placeholder', { attribute: attribute.name })}
                      isFilter
                      boundaryElement={bodyRef.current}
                    />
                  )
                case ProjectAttributeType.MULTI_SELECT:
                  return (
                    <MultiSelectAttribute
                      formGroup="attributes"
                      key={attribute.id}
                      attribute={attribute}
                      placeholder={t('modals.dashboard_filters.attributes_placeholder', { attribute: attribute.name })}
                      isFilter
                      boundaryElement={bodyRef.current}
                    />
                  )
                default:
                  return null
              }
            })}
          </Flex>
          <Flex justify="between" slot="actions">
            <Flex>
              {(appliedFilters(watchAllFields) > 0 || isStatusDirty || isOwnershipDirty) && (
                <WppActionButton variant="primary" onClick={onReset}>
                  <WppIconReset className={styles.resetIcon} />
                  {t('common.btn_reset')}
                </WppActionButton>
              )}
            </Flex>
            <Flex gap={12}>
              <WppButton variant="secondary" size="m" onClick={onClose}>
                {t('modals.dashboard_filters.btn_cancel')}
              </WppButton>
              <WppButton variant="primary" size="m" onClick={onSubmit} loading={isSubmitting}>
                {t('common.btn_apply')}
              </WppButton>
            </Flex>
          </Flex>
        </SideModal>
      </FormProvider>
    </>
  )
}

export const { showModal: showProjectFilterModal } = createNiceModal<Props>(ProjectFilterModal, 'project-filter-modal')
