import { projectApi } from 'api'
import { AttachmentMeta } from 'types/attaschments/attachments'
import { Template } from 'types/projects/template'

export interface Props {
  id: string
  name?: Template['name']
  tags?: Template['tags']
  description?: Template['description']
  types?: Template['types']
  files?: AttachmentMeta[]
  shortDescription?: Template['shortDescription']
}

export const patchWorkflowTemplateBlueprintApi = ({ id, ...data }: Props) =>
  projectApi.patch(`/templates/${id}/make_blueprint`, { ...data })
