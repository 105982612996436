import * as zod from 'zod'

import { FormSelectOption } from 'components/form/formSelect/FormSelect'
import { ProjectType } from 'types/projects/projects'

export interface ProjectTypeOption extends FormSelectOption {
  value: ProjectType
  label: string
}

export const VALIDATION = {
  name: {
    min: 3,
    max: 100,
  },
  description: {
    min: 30,
    max: 600,
  },
  shortDescription: {
    min: 30,
    max: 128,
  },
  tag: {
    min: 1,
    max: 40,
  },
}

export const templateValidationSchemaBlueprint = zod
  .object({
    name: zod
      .string()
      .min(VALIDATION.name.min)
      .max(VALIDATION.name.max, `Name should be from ${VALIDATION.name.min} to ${VALIDATION.name.max} characters long`),
    description: zod
      .string()
      .min(VALIDATION.description.min)
      .max(
        VALIDATION.description.max,
        `Description should be from ${VALIDATION.description.min} to ${VALIDATION.description.max} characters long`,
      ),
    shortDescription: zod
      .string()
      .min(VALIDATION.shortDescription.min)
      .max(
        VALIDATION.shortDescription.max,
        `Short description should be from ${VALIDATION.shortDescription.min} to ${VALIDATION.shortDescription.max} characters long`,
      )
      .optional(),
    projectTypes: zod.array(zod.nativeEnum(ProjectType)).nonempty({ message: 'Select at least one project type' }),
    tag: zod
      .union([
        zod.literal(''),
        zod.intersection(
          zod.intersection(
            zod.string().min(VALIDATION.tag.min, 'Tag should contain at least 1 character'),
            zod.string().max(VALIDATION.tag.max, 'Tag exceeded the maximum number of characters'),
          ),
          zod.string().regex(/^(\d|\w|\s)+$/, 'Special characters are not allowed'),
        ),
      ])
      .optional()
      .transform(value => value || ''),
    tags: zod.string().array().max(10),
  })
  .passthrough()
  .refine(({ tag, tags }) => !tags.includes(tag), {
    message: 'Tag already exists',
    path: ['tag'],
  })
  .optional()
