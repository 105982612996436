import { projectApi } from 'api'
import { AttachmentMeta } from 'types/attaschments/attachments'
import { Template } from 'types/projects/template'

export interface PatchWorkflowTemplate {
  id: string
  name?: Template['name']
  tags?: Template['tags']
  description?: Template['description']
  types?: Template['types']
  projectId?: Template['projectId']
  processType?: Template['processType']
  templateState?: Template['templateState']
  workflowEditable?: Template['workflowEditable']
  files?: AttachmentMeta[]
  shortDescription?: Template['shortDescription']
}

export const patchWorkflowTemplateApi = ({ id, ...body }: PatchWorkflowTemplate) =>
  projectApi.patch<Template>(`/templates/${id}`, body)
